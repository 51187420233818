import React, { Component, Suspense } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const SuppliersComponent = React.lazy(() => import("./views/setup/inventory/suppliers/Suppliers"));
const StockItemCategoriesComponent = React.lazy(() => import("./views/setup/inventory/categories/Categories"));
const StockItemsComponent = React.lazy(() => import("./views/setup/inventory/stockItems/StockItems"));
const PurchaseOrdersComponent = React.lazy(() => import("./views/modules/inventory/purchaseOrders/PurchaseOrders"));
const InventoryTrackingProfileComponent = React.lazy(() => import("./views/setup/inventory/inventoryTrackingProfiles/InventoryTrackingProfiles"));

const SalesComponent = React.lazy(() => import("./views/modules/sales/viewSales/Sales"));
const CancelledSalesComponent = React.lazy(() => import("./views/modules/sales/viewSales/CancelledSales"));
const SalesProjectionsOverrideComponent = React.lazy(() => import("./views/modules/sales/salesProjectionOverride/SalesProjectionOverride"));
const StorefrontCommissionsComponent = React.lazy(() => import("./views/modules/sales/storefrontCommissionRun/StorefrontCommissionRuns"));
const DriverSalesComponent = React.lazy(() => import("./views/modules/sales/driverSales/DriverSales"));

const TaskLogsComponent = React.lazy(() => import("./views/modules/foodSafety/taskLogs/TaskLogs"));
const StockItemCookingTemperatureLogsComponent = React.lazy(() => import("./views/modules/foodSafety/stockItems/cookingTemperatureLogs/TemperatureLogs"));
const StockItemStorageTemperatureLogsComponent = React.lazy(() => import("./views/modules/foodSafety/stockItems/storageTemperatureLogs/TemperatureLogs"));
const ApplianceTemperatureLogsComponent = React.lazy(() => import("./views/modules/foodSafety/appliances/temperatureLogs/TemperatureLogs"));
const IncidentLogsComponent = React.lazy(() => import("./views/modules/foodSafety/incidentLogs/IncidentLogs"));
const IncidentTypesComponent = React.lazy(() => import("./views/setup/foodSafety/incidents/IncidentTypes"));
const AppliancesComponent = React.lazy(() => import("./views/setup/foodSafety/appliances/Appliances"));
const TasksComponent = React.lazy(() => import("./views/setup/foodSafety/tasks/Tasks"));
const TaskTypesComponent = React.lazy(() => import("./views/setup/foodSafety/taskTypes/TaskTypes"));

const CustomersComponent = React.lazy(() => import("./views/modules/marketing/customers/Customers"));
const ImportCustomersComponent = React.lazy(() => import("./views/setup/imports/customers/ImportCustomers"));
const DiscountOffersComponent = React.lazy(() => import("./views/modules/marketing/discountOffers/DiscountOffers"));
const VoucherComponent = React.lazy(() => import("./views/modules/marketing/voucherCodes/VoucherCodes"));
const CampaignsComponent = React.lazy(() => import("./views/modules/marketing/campaign/Campaigns"));
const SignagePlayersComponent = React.lazy(() => import("./views/setup/display/Signage/SignagePlayers"));
const KioskSkinsComponent = React.lazy(() => import("./views/setup/display/KIOSK/KioskSkins"));

const StaffComponent = React.lazy(() => import("./views/modules/payroll/staff/Staff"));
const StaffRoleComponent = React.lazy(() => import("./views/modules/payroll/role/Role"));
const RotaComponent = React.lazy(() => import("./views/modules/payroll/rota/Rota"));
const HolidayPlannerComponent = React.lazy(() => import("./views/modules/payroll/holiday/HolidayPlanner"));
const PayrollCalculatorComponent = React.lazy(() => import("./views/modules/payroll/run/PayrollRun"));

const ExpensesComponent = React.lazy(() => import("./views/modules/accounts/expenses/Expenses"));
const BillsComponent = React.lazy(() => import("./views/modules/accounts/bills/Bills"));
const TransactionAccountsComponent = React.lazy(() => import("./views/setup/accounts/transactionAccounts/TransactionAccounts"));
const CreditBooksComponent = React.lazy(() => import("./views/setup/accounts/creditBooks/creditBooks/creditBooks"));
const CreditAccountsComponent = React.lazy(() => import("./views/setup/accounts/creditBooks/creditAccounts/creditAccounts"));
const CreditAccountStatusComponent = React.lazy(() => import("./views/modules/accounts/creditBooks/creditAccountStatus/CreditAccountStatus"));
const AccountCategoriesComponent = React.lazy(() => import("./views/setup/accounts/categories/Categories"));
const AccountSubCategoriesComponent = React.lazy(() => import("./views/setup/accounts/subCategories/SubCategories"));
const DocumentsExplorerComponent = React.lazy(() => import("./views/modules/documents/DocumentsExplorer"));

const UserRolesComponent = React.lazy(() => import("./views/security/users/UsersRoles"));
const AuditLogsComponent = React.lazy(() => import("./views/modules/auditLogs/AuditLogs"));

const FranchisorBasicConfigurationComponent = React.lazy(() => import("./views/setup/organization/franchisor/franchisorBasicConfiguration/FranchisorBasicConfiguration"));
const FranchisorOperatingConfigurationComponent = React.lazy(() => import("./views/setup/organization/franchisor/franchisorOperatingConfiguration/FranchisorOperatingConfiguration"));
const FranchisorDatabaseConfigurationComponent = React.lazy(() => import("./views/setup/organization/franchisor/franchisorDatabaseConfiguration/FranchisorDatabaseConfiguration"));
const FranchisorApiIntegrationsComponent = React.lazy(() => import("./views/setup/organization/franchisor/franchisorApiIntegration/FranchisorApiIntegrations"));
const BrandsComponent = React.lazy(() => import("./views/setup/organization/franchisor/brands/Brands"));
const FranchisorChargesConfigurationComponent = React.lazy(() => import("./views/setup/organization/franchisor/chargesConfiguration/Charges"));

const PropertyBasicConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyBasicConfiguration/PropertyBasicConfiguration"));
const PropertyOperatingConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyOperatingConfiguration/PropertyOperatingConfiguration"));
const PropertyPayrollConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyPayrollConfiguration/PropertyPayrollConfiguration"));
const PropertyLayoutConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyLayoutConfiguration/PropertyLayoutConfiguration"));
const PropertyStaffPermissionsConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyOperatingConfiguration/StaffPermissionsConfiguration"));
const PropertyPrintConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyPrintConfiguration/PropertyPrintConfiguration"));
const PropertyGeoConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyGeoConfiguration/PropertyGeoConfiguration"));
const PropertyFloatConfigurationComponent = React.lazy(() => import("./views/setup/organization/property/propertyFloatConfiguration/PropertyFloatConfiguration"));
const PropertyAddressConfigurationsComponent = React.lazy(() => import("./views/setup/organization/property/propertyAddressConfiguration/PropertyAddressConfigurations"));
const PropertyDeliveryManagementConfigurationComponent = React.lazy(() =>import("./views/setup/organization/property/propertyDeliveryManagementConfiguration/PropertyDeliveryManagementConfiguration"));
const PropertyLicenseSubscriptionsComponent = React.lazy(() => import("./views/setup/organization/property/propertyLicenseSubscriptions/PropertyLicenseSubscriptions.jsx"));
const LocalizationSettingOverrides = React.lazy(() => import("./views/setup/localization/LocalizationSettingOverrides"));

const StorefrontCoreComponent = React.lazy(() => import("./views/setup/organization/storefront/core/StorefrontCore"));
const StorefrontOperatingConfigurationsComponent = React.lazy(() => import("./views/setup/organization/storefront/operatingConfiguration/StorefrontOperatingConfigurations"));
const StorefrontPayoutConfigurationsComponent = React.lazy(() => import("./views/setup/organization/storefront/operatingConfiguration/StorefrontStorePayoutConfigurations"));
const StorefrontOpeningHoursComponent = React.lazy(() => import("./views/setup/organization/storefront/openingHours/StorefrontOpeningHours"));
const StorefrontBusinessHourOverridesComponent = React.lazy(() => import("./views/setup/organization/storefront/businessHourOverrides/StorefrontBusinessHourOverrides"));
const StorefrontDeliveryChargesComponent = React.lazy(() => import("./views/setup/organization/storefront/deliveryCharges/StorefrontDeliveryCharges"));
const StorefrontTipConfigurationComponent = React.lazy(() => import("./views/setup/organization/storefront/tipConfiguration/StorefrontTipConfiguration"));
const StorefrontOrderIngestComponent = React.lazy(() => import("./views/setup/organization/storefront/orderIngest/StorefrontOrderIngest"));
const StorefrontTimingConfigurationsComponent = React.lazy(() => import("./views/setup/organization/storefront/timingConfiguration/StorefrontTimingConfigurations"));
const StorefrontOverPhonePaymentConfigurationsComponent = React.lazy(() => import("./views/setup/organization/storefront/overPhonePaymentConfiguration/StorefrontOverPhonePaymentConfigurations"));
const StorefrontWebAppConfigurationsComponent = React.lazy(() => import("./views/setup/organization/storefront/webAppConfiguration/StorefrontWebAppConfigurations"));
const StorefrontMobileAppConfigurationsComponent = React.lazy(() => import("./views/setup/organization/storefront/mobileAppConfiguration/StorefrontMobileAppConfigurations"));

const PrintingTemplatesComponent = React.lazy(() => import("./views/setup/system/printingTemplates/PrintingTemplates"));
const StationsComponent = React.lazy(() => import("./views/setup/stations/StationsDesigner"));
const NetworkDiagramComponent = React.lazy(() => import("./views/setup/networkDiagram/NetworkDiagram"));
const TagsComponent = React.lazy(() => import("./views/setup/system/lookups/tags/Tags"));
const KitchenStationProfilesComponent = React.lazy(() => import("./views/setup/system/lookups/kitchenStationProfiles/KitchenStationProfiles"));
const PriceBandsComponent = React.lazy(() => import("./views/setup/system/lookups/priceBands/PriceBands"));
const PaymentTypesComponent = React.lazy(() => import("./views/setup/system/lookups/paymentTypes/PaymentTypes"));
const FreebieReasonsComponent = React.lazy(() => import("./views/setup/system/lookups/freebieReasons/FreebieReasons"));
const GuestsComponent = React.lazy(() => import("./views/setup/system/lookups/guests/Guests"));
const NomenclaturesComponent = React.lazy(() => import("./views/setup/system/lookups/nomenclatures/Nomenclatures"));
const AddressMappingComponent = React.lazy(() => import("./views/setup/system/lookups/addressManagement/addressMapping/AddressMapping"));

const SalesOverviewAnalyticsComponent = React.lazy(() => import("./views/reports/accounts/sales/salesAnalytics/SalesOverviewAnalytics"));
const SalesVatAnalyticsComponent = React.lazy(() => import("./views/reports/accounts/sales/vat/SalesVatAnalytics"));
const DailySummaryReportComponent = React.lazy(() => import("./views/reports/accounts/floatSessions/dailySummaryReport/DailySummaryReport"));
const DailySalesDiscountsComponent = React.lazy(() => import("./views/bi/sales/DailySalesDiscounts"));
const DailySalesRefundsComponent = React.lazy(() => import("./views/bi/sales/DailySalesRefunds"));
const DailySalesChargesComponent = React.lazy(() => import("./views/bi/sales/DailySalesChargesReport/DailySalesCharges"));
const DepositTransactionReportComponent = React.lazy(() => import("./views/reports/accounts/floatSessions/depositTransactionReport/DepositTransactionReport"));
const HourlySalesComponent = React.lazy(() => import("./views/bi/sales/HourlySales"));
const SaleTimingAnalyticsComponent = React.lazy(() => import("./views/reports/accounts/sales/timingAnalytics/SaleTimingAnalytics"));

const PeriodicCostSummaryReportComponent = React.lazy(() => import("./views/bi/general/costsSummaryReport/PeriodicCostSummaryReport"));
const DailyPayrollCostsComponent = React.lazy(() => import("./views/bi/payroll/DailyPayrollCosts/DailyPayrollCosts"));
const DailyVenueCommissionsComponent = React.lazy(() => import("./views/bi/accounts/DailyVenueCommissions"));
const CogsSummaryReportComponent = React.lazy(() => import("./views/bi/inventory/cogs/CogsSummaryReport"));
const FixedCostsComponent = React.lazy(() => import("./views/bi/accounts/FixedCosts"));
const DailyStaffMealsComponent = React.lazy(() => import("./views/bi/payroll/DailyStaffMeals"));
const DailyFreebiesMealsComponent = React.lazy(() => import("./views/bi/payroll/DailyFreebiesMeals"));
const DailyGuestMealsComponent = React.lazy(() => import("./views/bi/payroll/DailyGuestMeals"));

const DailySalesByDriverComponent = React.lazy(() => import("./views/bi/payroll/DailySalesByDriver"));
const DailyUpsellingPointsComponent = React.lazy(() => import("./views/bi/payroll/DailyUpsellingPoints"));

const InventoryPurchaseReportComponent = React.lazy(() => import("./views/bi/inventory/purchaseReport/InventoryPurchaseReport"));
const WastagesReportComponent = React.lazy(() => import("./views/bi/inventory/wastagesReport/WastagesReport"));
const DiscrepanciesReportComponent = React.lazy(() => import("./views/bi/inventory/discrepancies/DiscrepanciesReport"));
const InventoryStatusComponent = React.lazy(() => import("./views/bi/inventory/inventoryStatus/InventoryStatusReport"));
const SalesByProductReportComponent = React.lazy(() => import("./views/bi/general/costsSummaryReport/PeriodicCostSummaryReport"));

const SalesByMenuItemComponent = React.lazy(() => import("./views/reports/accounts/sales/menuSales/salesByMenuItem/SalesByMenuItem"));
const HourlySalesByMenuItemComponent = React.lazy(() => import("./views/reports/accounts/sales/menuSales/hourlySalesByMenuItem/HourlySalesByMenuItem"));
const SalesByReportingTagComponent = React.lazy(() => import("./views/reports/accounts/sales/menuSales/salesByReportingTag/SalesByReportingTag"));
const HourlySalesByReportingTagComponent = React.lazy(() => import("./views/reports/accounts/sales/menuSales/hourlySalesByReportingTag/HourlySalesByReportingTag"));
const RefundsByMenuItemComponent = React.lazy(() => import("./views/reports/accounts/sales/menuSales/refundsByMenuItem/RefundsByMenuItem"));
const MenuPublishLogs = React.lazy(() => import("./embeddedViews/MenuPublishLogs"));
const MenuPublishing = React.lazy(() => import("./embeddedViews/MenuPublishing.jsx"));
const MenusPublishOverview = React.lazy(() => import("./embeddedViews/MenusPublishOverview.jsx"));
const MenuImport = React.lazy(() => import("./embeddedViews/MenuImport/MenuImport.jsx"));
const MenuExport = React.lazy(() => import("./embeddedViews/MenuExport/MenuExport.jsx"));
const MenuAuditLogs = React.lazy(() => import("./embeddedViews/MenuAuditLogs.jsx"));
const MenuClone = React.lazy(() => import("./embeddedViews/MenuClone.jsx"));

const  stateManager = require("./utils/state/stateManager.js");

class EmbeddedApp extends Component {
    render() {
        const globalState = stateManager.getStore().getState();
        const applicationModeData = globalState.applicationModeData;

        return (
            <Router>
                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/modules/inventory/suppliers" component={SuppliersComponent} />
                        <Route path="/modules/inventory/stockItemCategories" component={StockItemCategoriesComponent} />
                        <Route path="/modules/inventory/stockItems" component={StockItemsComponent} />
                        <Route path="/modules/inventory/purchaseOrders" component={PurchaseOrdersComponent} />
                        <Route path="/modules/inventory/inventoryTrackingProfiles" component={InventoryTrackingProfileComponent} />

                        <Route path="/modules/sales/all" component={SalesComponent} />
                        <Route path="/modules/sales/cancelledSales" component={CancelledSalesComponent} />
                        <Route path="/modules/sales/salesProjections" component={SalesProjectionsOverrideComponent} />
                        <Route path="/modules/sales/storefrontCommissions" component={StorefrontCommissionsComponent} />
                        <Route path="/modules/sales/driverSales" component={DriverSalesComponent} />

                        <Route path="/modules/payroll/staff" component={StaffComponent} />
                        <Route path="/modules/payroll/role" component={StaffRoleComponent} />
                        <Route path="/modules/payroll/rota" component={RotaComponent} />
                        <Route path="/modules/payroll/holidayPlanner" component={HolidayPlannerComponent} />
                        <Route path="/modules/payroll/payrollCalculator" component={PayrollCalculatorComponent} />

                        <Route path="/modules/foodSafety/taskLogs" component={TaskLogsComponent} />
                        <Route path="/modules/foodSafety/stockItemCookingTemperatureLogs" component={StockItemCookingTemperatureLogsComponent} />
                        <Route path="/modules/foodSafety/stockItemStorageTemperatureLogs" component={StockItemStorageTemperatureLogsComponent} />
                        <Route path="/modules/foodSafety/applianceTemperatureLogs" component={ApplianceTemperatureLogsComponent} />
                        <Route path="/modules/foodSafety/incidentTypes" component={IncidentTypesComponent} />
                        <Route path="/modules/foodSafety/incidentLogs" component={IncidentLogsComponent} />
                        <Route path="/modules/foodSafety/appliance" component={AppliancesComponent} />
                        <Route path="/modules/foodSafety/tasks" component={TasksComponent} />
                        <Route path="/modules/foodSafety/taskTypes" component={TaskTypesComponent} />

                        <Route path="/modules/marketing/customers" component={CustomersComponent} />
                        <Route path="/modules/marketing/importCustomers" component={ImportCustomersComponent} />
                        <Route path="/modules/marketing/discountOffers" component={DiscountOffersComponent} />
                        <Route path="/modules/marketing/voucherCodes" component={VoucherComponent} />
                        <Route path="/modules/marketing/campaigns" component={CampaignsComponent} />
                        <Route path="/modules/display/signagePlayers" component={SignagePlayersComponent} />
                        <Route path="/modules/display/kioskSkins" component={KioskSkinsComponent} />

                        <Route path="/modules/accounts/expenses" component={ExpensesComponent} />
                        <Route path="/modules/accounts/bills" component={BillsComponent} />
                        <Route path="/modules/accounts/transactionAccounts" component={TransactionAccountsComponent} />
                        <Route path="/modules/accounts/creditBooks" component={CreditBooksComponent} />
                        <Route path="/modules/accounts/creditAccounts" component={CreditAccountsComponent} />
                        <Route path="/modules/accounts/creditAccountStatus" component={CreditAccountStatusComponent} />
                        <Route path="/modules/accounts/categories" component={AccountCategoriesComponent} />
                        <Route path="/modules/accounts/subCategories" component={AccountSubCategoriesComponent} />
                        <Route path="/modules/documents" component={DocumentsExplorerComponent} />

                        <Route path="/modules/security/userRoles" component={UserRolesComponent} />
                        <Route path="/modules/auditLogs" component={AuditLogsComponent} />

                        <Route path="/configurations/franchisors/basicConfiguration" component={FranchisorBasicConfigurationComponent} />
                        <Route path="/configurations/franchisors/operatingConfiguration" component={FranchisorOperatingConfigurationComponent} />
                        <Route path="/configurations/franchisors/dbConfiguration" component={FranchisorDatabaseConfigurationComponent} />
                        <Route path="/configurations/franchisors/apiIntegrations" component={FranchisorApiIntegrationsComponent} />
                        <Route path="/configurations/franchisors/chargesConfiguration" component={FranchisorChargesConfigurationComponent} />
                        <Route path="/configurations/franchisors/brands" component={BrandsComponent} />
                        <Route path="/configurations/franchisors/localizationConfiguration" component={LocalizationSettingOverrides} />
                        <Route path="/configurations/franchisors/properties/addNewProperty" component={PropertyBasicConfigurationComponent} />

                        <Route path="/configurations/franchisors/properties/basicConfiguration" component={PropertyBasicConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/operatingConfiguration" component={PropertyOperatingConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/payrollConfiguration" component={PropertyPayrollConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/layoutConfiguration" component={PropertyLayoutConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/staffPermissionsConfiguration" component={PropertyStaffPermissionsConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/printConfiguration" component={PropertyPrintConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/geoConfiguration" component={PropertyGeoConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/floatConfiguration" component={PropertyFloatConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/addressConfiguration" component={PropertyAddressConfigurationsComponent} />
                        <Route path="/configurations/franchisors/properties/deliveryConfiguration" component={PropertyDeliveryManagementConfigurationComponent} />
                        <Route path="/configurations/franchisors/properties/licenseSubscriptions" component={PropertyLicenseSubscriptionsComponent} />
                        <Route path="/configurations/franchisors/properties/localizationConfiguration" component={LocalizationSettingOverrides} />

                        <Route path="/configurations/franchisors/properties/storefronts/addNewStorefront" render={(props) => <StorefrontCoreComponent params={{}} />} />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/basicConfiguration"
                            render={(props) => <StorefrontCoreComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/operatingConfiguration"
                            render={(props) => <StorefrontOperatingConfigurationsComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/payoutConfiguration"
                            render={(props) => <StorefrontPayoutConfigurationsComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/openingHours"
                            render={(props) => <StorefrontOpeningHoursComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/openingHoursOverrides"
                            render={(props) => <StorefrontBusinessHourOverridesComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/deliveryCharges"
                            render={(props) => <StorefrontDeliveryChargesComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/tipConfiguration"
                            render={(props) => <StorefrontTipConfigurationComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/orderIngest"
                            render={(props) => <StorefrontOrderIngestComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/timingsConfiguration"
                            render={(props) => <StorefrontTimingConfigurationsComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/overPhonePayment"
                            render={(props) => <StorefrontOverPhonePaymentConfigurationsComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/webAppConfiguration"
                            render={(props) => <StorefrontWebAppConfigurationsComponent params={applicationModeData.selectedStorefront} />}
                        />
                        <Route
                            path="/configurations/franchisors/properties/storefronts/mobileAppConfiguration"
                            render={(props) => <StorefrontMobileAppConfigurationsComponent params={applicationModeData.selectedStorefront} />}
                        />

                        <Route path="/configurations/system/printingTemplates/receipt" render={(props) => <PrintingTemplatesComponent type="receipt" />} />
                        <Route path="/configurations/system/printingTemplates/kitchenStation" render={(props) => <PrintingTemplatesComponent type="kitchenStation" />} />
                        <Route path="/configurations/system/printingTemplates/voucher" render={(props) => <PrintingTemplatesComponent type="voucher" />} />
                        <Route path="/configurations/system/printingTemplates/floatSession" render={(props) => <PrintingTemplatesComponent type="floatSession" />} />
                        <Route path="/configurations/system/printingTemplates/dailySummaryReport" render={(props) => <PrintingTemplatesComponent type="dailySummaryReport" />} />

                        <Route path="/configurations/system/stations" component={StationsComponent} />
                        <Route path="/configurations/system/networkDiagram" component={NetworkDiagramComponent} />
                        <Route path="/configurations/system/tags" component={TagsComponent} />
                        <Route path="/configurations/system/kitchenStationProfiles" component={KitchenStationProfilesComponent} />
                        <Route path="/configurations/system/priceBands" component={PriceBandsComponent} />
                        <Route path="/configurations/system/paymentTypes" component={PaymentTypesComponent} />
                        <Route path="/configurations/system/freebieReasons" component={FreebieReasonsComponent} />
                        <Route path="/configurations/system/guests" component={GuestsComponent} />
                        <Route path="/configurations/system/nomenclatures" component={NomenclaturesComponent} />
                        <Route path="/configurations/system/addressMapping" component={AddressMappingComponent} />

                        <Route path="/configurations/menu/publishLogs" component={MenuPublishLogs} />
                        <Route path="/configurations/menu/publish" component={MenuPublishing} />
                        <Route path="/configurations/menu/publishOverview" component={MenusPublishOverview} />
                        <Route path="/configurations/menu/import" component={MenuImport} />
                        <Route path="/configurations/menu/export" component={MenuExport} />
                        <Route path="/configurations/menu/logs" component={MenuAuditLogs} />
                        <Route path="/configurations/menu/clone" component={MenuClone} />

                        <Route path="/reports/sales/overview" component={SalesOverviewAnalyticsComponent} />
                        <Route path="/reports/sales/vat" component={SalesVatAnalyticsComponent} />
                        <Route path="/reports/sales/endOfDay" component={DailySummaryReportComponent} />
                        <Route path="/reports/sales/discounts" component={DailySalesDiscountsComponent} />
                        <Route path="/reports/sales/refunds" component={DailySalesRefundsComponent} />
                        <Route path="/reports/sales/charges" component={DailySalesChargesComponent} />
                        <Route path="/reports/sales/drs" component={DepositTransactionReportComponent} />
                        <Route path="/reports/sales/timings" component={SaleTimingAnalyticsComponent} />
                        <Route path="/reports/sales/hourly" component={HourlySalesComponent} />

                        <Route path="/reports/costs/overview" component={PeriodicCostSummaryReportComponent} />
                        <Route path="/reports/costs/wages" component={DailyPayrollCostsComponent} />
                        <Route path="/reports/costs/venueCommissions" component={DailyVenueCommissionsComponent} />
                        <Route path="/reports/costs/cogsSummary" component={CogsSummaryReportComponent} />
                        <Route path="/reports/costs/fixedCosts" component={FixedCostsComponent} />
                        <Route path="/reports/costs/staffMeals" component={DailyStaffMealsComponent} />
                        <Route path="/reports/costs/freebies" component={DailyFreebiesMealsComponent} />
                        <Route path="/reports/costs/guestMeals" component={DailyGuestMealsComponent} />

                        <Route path="/reports/payroll/driverSales" component={DailySalesByDriverComponent} />
                        <Route path="/reports/payroll/upsellingPoints" component={DailyUpsellingPointsComponent} />

                        <Route path="/reports/inventory/purchaseOrders" component={InventoryPurchaseReportComponent} />
                        <Route path="/reports/inventory/wastages" component={WastagesReportComponent} />
                        <Route path="/reports/inventory/discrepancies" component={DiscrepanciesReportComponent} />
                        <Route path="/reports/inventory/inventoryStatus" component={InventoryStatusComponent} />
                        <Route path="/reports/inventory/salesByProduct" component={SalesByProductReportComponent} />

                        <Route path="/reports/menu/salesByMenuItem" component={SalesByMenuItemComponent} />
                        <Route path="/reports/menu/hourlySalesByMenuItem" component={HourlySalesByMenuItemComponent} />
                        <Route path="/reports/menu/salesByReportingTag" component={SalesByReportingTagComponent} />
                        <Route path="/reports/menu/hourlySalesByReportingTag" component={HourlySalesByReportingTagComponent} />
                        <Route path="/reports/menu/refundsByMenuItem" component={RefundsByMenuItemComponent} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

export default EmbeddedApp;
